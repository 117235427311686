import { lazy } from 'react';
import { Navigate } from 'react-router';
import { getCache } from 'helpers/local-storage';
import Loadable from 'component/Loadable';
import MainLayout from 'layout/MainLayout';

const DashboardDefault = Loadable(lazy(() => import('views/Dashboard')));

const PaymentMethod = Loadable(lazy(() => import('views/PaymentMethod')));
const FormPaymentMethod = Loadable(lazy(() => import('views/PaymentMethod/form')));

const EventType = Loadable(lazy(() => import('views/Event/Type')));
const FormEventType = Loadable(lazy(() => import('views/Event/Type/form')));
const EventList = Loadable(lazy(() => import('views/Event/List')));
const FormEventList = Loadable(lazy(() => import('views/Event/List/form')));

const ProductType = Loadable(lazy(() => import('views/Product/Type')));
const FormProductType = Loadable(lazy(() => import('views/Product/Type/form')));
const ProductList = Loadable(lazy(() => import('views/Product/List')));
const FormProductList = Loadable(lazy(() => import('views/Product/List/form')));

const UIStoreVariant = Loadable(lazy(() => import('views/UIStore/Variant')));
const FormUIStoreVariant = Loadable(lazy(() => import('views/UIStore/Variant/form')));
const UIStoreVariantDetail = Loadable(lazy(() => import('views/UIStore/VariantDetail')));
const FormUIStoreVariantDetail = Loadable(lazy(() => import('views/UIStore/VariantDetail/form')));
const UIStoreCategory = Loadable(lazy(() => import('views/UIStore/Category')));
const FormUIStoreCategory = Loadable(lazy(() => import('views/UIStore/Category/form')));
const UIStoreProduct = Loadable(lazy(() => import('views/UIStore/Product')));
const FormUIStoreProduct = Loadable(lazy(() => import('views/UIStore/Product/form')));
const UIStoreStatus = Loadable(lazy(() => import('views/UIStore/Status')));
const FormUIStoreStatus = Loadable(lazy(() => import('views/UIStore/Status/form')));
const UIStoreTransaction = Loadable(lazy(() => import('views/UIStore/Transaction')));

// ==============================|| MAIN ROUTES ||============================== //

const accessToken = getCache('access_token');
const email = getCache('email');

const renderElement = () => {
  if (accessToken) {
    return <MainLayout />;
  } else {
    return <Navigate to="/login" />;
  }
};

const renderPages = () => {
  if (email == 'adminmobile@ui.ac.id') {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      {
        path: 'payment-method',
        children: [
          { index: true, element: <PaymentMethod /> },
          { path: ':type/:id?', element: <FormPaymentMethod /> }
        ]
      },
      {
        path: 'event',
        children: [
          {
            path: 'type',
            children: [
              { index: true, element: <EventType /> },
              { path: ':type/:id?', element: <FormEventType /> }
            ]
          },
          {
            path: 'list',
            children: [
              { index: true, element: <EventList /> },
              { path: ':type/:id?', element: <FormEventList /> }
            ]
          }
        ]
      },
      {
        path: 'product',
        children: [
          {
            path: 'type',
            children: [
              { index: true, element: <ProductType /> },
              { path: ':type/:id?', element: <FormProductType /> }
            ]
          },
          {
            path: 'list',
            children: [
              { index: true, element: <ProductList /> },
              { path: ':type/:id?', element: <FormProductList /> }
            ]
          }
        ]
      },
      {
        path: 'ui-store',
        children: [
          {
            path: 'variant',
            children: [
              { index: true, element: <UIStoreVariant /> },
              { path: ':type/:id?', element: <FormUIStoreVariant /> }
            ]
          },
          {
            path: 'variant-detail',
            children: [
              { index: true, element: <UIStoreVariantDetail /> },
              { path: ':type/:id?', element: <FormUIStoreVariantDetail /> }
            ]
          },
          {
            path: 'category',
            children: [
              { index: true, element: <UIStoreCategory /> },
              { path: ':type/:id?', element: <FormUIStoreCategory /> }
            ]
          },
          {
            path: 'product',
            children: [
              { index: true, element: <UIStoreProduct /> },
              { path: ':type/:id?', element: <FormUIStoreProduct /> }
            ]
          },
          {
            path: 'status',
            children: [
              { index: true, element: <UIStoreStatus /> },
              { path: ':type/:id?', element: <FormUIStoreStatus /> }
            ]
          },
          { path: 'transaction', element: <UIStoreTransaction /> }
        ]
      }
    ];
  } else if (email == 'uistore@uimobile.net') {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      {
        path: 'ui-store',
        children: [
          {
            path: 'variant',
            children: [
              { index: true, element: <UIStoreVariant /> },
              { path: ':type/:id?', element: <FormUIStoreVariant /> }
            ]
          },
          {
            path: 'variant-detail',
            children: [
              { index: true, element: <UIStoreVariantDetail /> },
              { path: ':type/:id?', element: <FormUIStoreVariantDetail /> }
            ]
          },
          {
            path: 'category',
            children: [
              { index: true, element: <UIStoreCategory /> },
              { path: ':type/:id?', element: <FormUIStoreCategory /> }
            ]
          },
          {
            path: 'product',
            children: [
              { index: true, element: <UIStoreProduct /> },
              { path: ':type/:id?', element: <FormUIStoreProduct /> }
            ]
          },
          {
            path: 'status',
            children: [
              { index: true, element: <UIStoreStatus /> },
              { path: ':type/:id?', element: <FormUIStoreStatus /> }
            ]
          },
          { path: 'transaction', element: <UIStoreTransaction /> }
        ]
      }
    ];
  } else {
    return [
      { path: 'dashboard', element: <DashboardDefault /> },
      {
        path: 'payment-method',
        children: [
          { index: true, element: <PaymentMethod /> },
          { path: ':type/:id?', element: <FormPaymentMethod /> }
        ]
      },
      {
        path: 'event',
        children: [
          {
            path: 'type',
            children: [
              { index: true, element: <EventType /> },
              { path: ':type/:id?', element: <FormEventType /> }
            ]
          },
          {
            path: 'list',
            children: [
              { index: true, element: <EventList /> },
              { path: ':type/:id?', element: <FormEventList /> }
            ]
          }
        ]
      },
      {
        path: 'product',
        children: [
          {
            path: 'type',
            children: [
              { index: true, element: <ProductType /> },
              { path: ':type/:id?', element: <FormProductType /> }
            ]
          },
          {
            path: 'list',
            children: [
              { index: true, element: <ProductList /> },
              { path: ':type/:id?', element: <FormProductList /> }
            ]
          }
        ]
      }
    ];
  }
};

const MainRoutes = {
  path: '/',
  element: renderElement(),
  children: renderPages()
};

export default MainRoutes;
