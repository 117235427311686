import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import { getCache } from 'helpers/local-storage';

const email = getCache('email');

const renderMenuItems = () => {
  if (email == 'adminmobile@ui.ac.id') {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          },
          {
            id: 'payment-method',
            title: 'Metode Pembayaran',
            type: 'item',
            icon: PaymentOutlinedIcon,
            url: '/payment-method'
          },
          {
            id: 'event',
            title: 'Event',
            type: 'collapse',
            icon: EventOutlinedIcon,
            children: [
              {
                id: 'event-type',
                title: 'Tipe Event',
                type: 'item',
                url: '/event/type'
              },
              {
                id: 'event-list',
                title: 'List Event',
                type: 'item',
                url: '/event/list'
              }
            ]
          },
          {
            id: 'product',
            title: 'Produk',
            type: 'collapse',
            icon: CategoryOutlinedIcon,
            children: [
              {
                id: 'product-type',
                title: 'Tipe Produk',
                type: 'item',
                url: '/product/type'
              },
              {
                id: 'product-list',
                title: 'List Produk',
                type: 'item',
                url: '/product/list'
              }
            ]
          },
          {
            id: 'ui-store',
            title: 'UI Store',
            type: 'collapse',
            icon: StorefrontOutlinedIcon,
            children: [
              {
                id: 'variant',
                title: 'Varian',
                type: 'item',
                url: '/ui-store/variant'
              },
              {
                id: 'variant-detail',
                title: 'Detail Varian',
                type: 'item',
                url: '/ui-store/variant-detail'
              },
              {
                id: 'ui-store-category',
                title: 'Kategori Produk',
                type: 'item',
                url: '/ui-store/category'
              },
              {
                id: 'ui-store-product',
                title: 'Produk',
                type: 'item',
                url: '/ui-store/product'
              },
              {
                id: 'status-transaction',
                title: 'Status Transaksi',
                type: 'item',
                url: '/ui-store/status'
              },
              {
                id: 'transaction',
                title: 'Transaksi',
                type: 'item',
                url: '/ui-store/transaction'
              }
            ]
          }
        ]
      }
    ];
  } else if (email == 'uistore@uimobile.net') {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          },
          {
            id: 'ui-store',
            title: 'UI Store',
            type: 'collapse',
            icon: StorefrontOutlinedIcon,
            children: [
              {
                id: 'variant',
                title: 'Varian',
                type: 'item',
                url: '/ui-store/variant'
              },
              {
                id: 'variant-detail',
                title: 'Detail Varian',
                type: 'item',
                url: '/ui-store/variant-detail'
              },
              {
                id: 'ui-store-category',
                title: 'Kategori Produk',
                type: 'item',
                url: '/ui-store/category'
              },
              {
                id: 'ui-store-product',
                title: 'Produk',
                type: 'item',
                url: '/ui-store/product'
              },
              {
                id: 'status-transaction',
                title: 'Status Transaksi',
                type: 'item',
                url: '/ui-store/status'
              },
              {
                id: 'transaction',
                title: 'Transaksi',
                type: 'item',
                url: '/ui-store/transaction'
              }
            ]
          }
        ]
      }
    ];
  } else {
    return [
      {
        id: 'navigation',
        type: 'group',
        children: [
          {
            id: 'dashboard',
            title: 'Dashboard',
            type: 'item',
            icon: HomeOutlinedIcon,
            url: '/dashboard'
          },
          {
            id: 'payment-method',
            title: 'Metode Pembayaran',
            type: 'item',
            icon: PaymentOutlinedIcon,
            url: '/payment-method'
          },
          {
            id: 'event',
            title: 'Event',
            type: 'collapse',
            icon: EventOutlinedIcon,
            children: [
              {
                id: 'event-type',
                title: 'Tipe Event',
                type: 'item',
                url: '/event/type'
              },
              {
                id: 'event-list',
                title: 'List Event',
                type: 'item',
                url: '/event/list'
              }
            ]
          },
          {
            id: 'product',
            title: 'Produk',
            type: 'collapse',
            icon: CategoryOutlinedIcon,
            children: [
              {
                id: 'product-type',
                title: 'Tipe Produk',
                type: 'item',
                url: '/product/type'
              },
              {
                id: 'product-list',
                title: 'List Produk',
                type: 'item',
                url: '/product/list'
              }
            ]
          }
        ]
      }
    ];
  }
};

export default {
  items: renderMenuItems()
};
